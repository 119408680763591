<template>
  <a-modal
    :get-container="modalRef"
    v-model:visible="visible"
    :mask-closable="false"
    title="审核结果"
    :after-close="afterClose"
  >
    <template #footer>
      <a-button :disabled="disabled" @click="visible = false">取消</a-button>
    </template>
    <div style="max-height: 450px; overflow-y: scroll">
      <a-form>
        <a-descriptions bordered size="small" :column="1">
          <a-descriptions-item label="审核人员">{{
            auditRecordVo.auditedBy
          }}</a-descriptions-item>
          <a-descriptions-item label="审核结果">{{
            auditRecordVo.auditState == -1 ? "驳回退款" : "同意退款"
          }}</a-descriptions-item>
          <a-descriptions-item label="审核时间">{{
            auditRecordVo.auditTime
          }}</a-descriptions-item>
          <a-descriptions-item label="审核说明">{{
            auditRecordVo.remark
          }}</a-descriptions-item>
          <a-descriptions-item label="申请退款原因">{{
            auditRecordVo.reason
          }}</a-descriptions-item>
        </a-descriptions>
      </a-form>
    </div>
  </a-modal>
</template>

<script>
import { ref, onMounted, toRefs } from "vue";
import useMessage from "@/hooks/useMessage";
import useRefundRecord from "../../../../hooks/Info/useRefundRecord";
import { detail } from "../../../../api/info/refundRecordApi";

export default {
  name: "Audit",
  props: ["modalRef", "id"],
  emits: ["close"],
  setup(props, { emit }) {
    const disabled = ref(false);
    const { auditVo, auditRecordVo } = useRefundRecord();

    const { showErr } = useMessage();
    const visible = ref(true);
    let refresh = false;
    const afterClose = () => {
      emit("close", refresh);
    };
    onMounted(async () => {
      try {
        let { data } = await detail(props.id);
        auditRecordVo.auditedBy = data.auditedBy;
        auditRecordVo.auditState = data.auditState;
        auditRecordVo.auditTime = data.auditTime;
        auditRecordVo.remark = data.remark;
        auditRecordVo.reason = data.reason;
      } catch (e) {
        await showErr(e);
      }
    });
    return {
      ...toRefs(props),
      disabled,
      visible,
      afterClose,
      auditVo,
      auditRecordVo,
    };
  },
};
</script>

<style scoped></style>
